
import BNotification from 'buetify/lib/components/notices/notification/BNotification';
import { defineComponent } from 'vue';
import UserIcon from '../../../../../components/icons/UserIcon';

export default defineComponent({
	name: 'notification-icons-example',
	components: {
		BNotification
	},
	setup() {
		return {
			UserIcon
		};
	}
});
