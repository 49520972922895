
import BNotification from 'buetify/lib/components/notices/notification/BNotification';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'notification-variants-example',
	components: {
		BNotification
	}
});
