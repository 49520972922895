
import BButton from 'buetify/lib/components/button/BButton';
import BNotification from 'buetify/lib/components/notices/notification/BNotification';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'simple-notification-example',
	components: {
		BNotification,
		BButton
	},
	setup() {
		const isActive = shallowRef(true);

		function toggle() {
			isActive.value = !isActive.value;
		}

		return {
			isActive,
			toggle
		};
	}
});
